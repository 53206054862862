<template>
  <div class="tablePlanner">
    <a-table
      :pagination="{ pageSize: 10, hideOnSinglePage: true }"
      :columns="columns"
      :data-source="dataTable"
      style="margin-top: 3rem"
    >
      <!-- <span slot="Topic" slot-scope="text" v-if="text == 'Done'">Class Done</span> -->
      <span slot="Live" slot-scope="text">
        <a-icon
          type="video-camera"
          v-if="text == 'Live'"
          style="font-size:20px;"
          theme="twoTone"
          two-tone-color="#eb2f96"
        />
      </span>
      <!-- <span slot="Class" slot-scope="text">
        <a @click.prevent="toDetail(text.id)" class="btn btn-outline-success btnKelas">{{text}}</a>
      </span>-->
      <span slot="Assign" slot-scope="text">
        <a-icon
          type="paper-clip"
          v-if="text == 'File Upload'"
          style="font-size:20px; color: #1B55E3;"
        />
        <a-icon type="align-left" v-if="text == 'Essay'" style="font-size:20px; color: #1B55E3;" />
      </span>
    </a-table>
  </div>
</template>
<script>
import moment from 'moment'
const columns = [
  // {
  //   title: 'No',
  //   dataIndex: 'No',
  //   key: 'No',
  //   width: '8%',
  //   scopedSlots: { customRender: 'No' },
  // },
  {
    title: 'Class',
    dataIndex: 'Class',
    key: 'Class',
    // width: '12%',
    scopedSlots: { customRender: 'Class' },
  },
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
    // width: 'auto',
    scopedSlots: { customRender: 'Date' },
  },
  {
    title: 'Topic',
    dataIndex: 'Topic',
    key: 'Topic',
    // width: '17%',
    scopedSlots: { customRender: 'Topic' },
  },
  {
    title: 'Duration',
    dataIndex: 'Duration',
    key: 'Duration',
    // width: '9%',
    scopedSlots: { customRender: 'Duration' },
  },
  {
    title: 'Updated',
    dataIndex: 'Updated',
    key: 'Updated',
    // width: '17%',
    scopedSlots: { customRender: 'Updated' },
  },
  {
    title: 'Live',
    dataIndex: 'Live',
    key: 'Live',
    // width: '7%',
    scopedSlots: { customRender: 'Live' },
  },
  {
    title: 'Assign',
    dataIndex: 'Assign',
    key: 'Assign',
    // width: '8%',
    scopedSlots: { customRender: 'Assign' },
  },
]

export default {
  props: ['dataTable'],
  data() {
    return {
      columns,
      moment,
    }
  },
}
</script>

<style lang="scss">
.tablePlanner {
  .btnKelas {
    width: 150px;
    border-color: #038080;
    color: #038080;
  }

  .btnKelas:hover {
    background: #038080 !important;
    border-color: #038080 !important;
    color: white;
  }
  .btnKelas:active {
    background: #038080 !important;
    color: white;
  }

  // .tableGradeCbt {
    .ant-spin-nested-loading .ant-spin-container .ant-table {
      overflow-x: auto;
      table {
        @media (max-width: 769px) {
          width: auto;
        }
      }
    }
  // }
}
</style>
