<template>
  <LoadingState v-if="isLoading" />
  <div class="planning" v-else>
    <a-row v-if="biweeklyTopic">
      <a-col :span="24" class="mb-5">
        <h1>Session Planner</h1>
      </a-col>
      <div>
        <a-col :md="{ span: 24 }" :lg="{ span: 12 }">
          <h2 class="titleBiweekly">
            Biweekly Session
            <span
              class="font-size-14 font-weight-normal"
              style="color: #0000009C;"
            >{{biweeklyTopic.length ? biweeklyTopic.length : 0}} Session</span>
          </h2>
        </a-col>
        <a-col
          :md="{ span: 24 }"
          :lg="{ span: 12 }"
          :style="{
          'text-align': isDesktop ? 'right' : 'left',
          color: '#0000009C'
        }"
        >
          <span>{{biweeklyDate}}</span>
        </a-col>
      </div>
    </a-row>
    <div v-if="biweeklyTopic">
      <VueSlickCarousel
        v-bind="settings"
        ref="carousel"
        v-if="biweeklyTopic.length > 3 || isMobile"
      >
        <div v-for="(item, index) in biweeklyTopic" :key="index">
          <CardBiweekly :item="item" />
        </div>
      </VueSlickCarousel>
      <template v-else-if="biweeklyTopic.length > 0 && biweeklyTopic.length <= 3 && !isMobile">
        <a-row>
          <a-col
            :md="{ span: 24 }"
            :lg="{ span: 8 }"
            v-for="(item, index) in biweeklyTopic"
            :key="index"
          >
            <CardBiweekly :item="item" />
          </a-col>
        </a-row>
      </template>
      <div v-else class="my-5">
        <EmptyState :heading="headingEmptyState" :description="descriptionEmptyState" />
      </div>
    </div>
    <a-row class="mt-4">
      <a-col :span="24">
        <div class="card-slots">
          <div class="nav-slots">
            <a-menu v-if="menuSlots.length" v-model="current" mode="horizontal">
              <a-menu-item
                v-for="(slot, index) in menuSlots"
                :key="index"
                @click="showOverview(slot['mata_pelajaran.nama'], slot['kela.tingkat'])"
              >{{slot["kela.tingkat"]}} | {{slot["mata_pelajaran.nama"].toUpperCase()}}</a-menu-item>
            </a-menu>
          </div>
          <div class="detail-slot" v-if="dataOfTable">
            <a-row>
              <a-col :xs="{ span: 12 }" :lg="{ span: 6 }" class="mb-3">
                <h3 class="type">Mata Pelajaran</h3>
                <p class="value">{{overviewSlot.mapel ? overviewSlot.mapel : '-'}}</p>
              </a-col>
              <a-col :xs="{ span: 12 }" :lg="{ span: 6 }" class="mb-3">
                <h3 class="type">Tingkat</h3>
                <p class="value">Tingkat {{overviewSlot.tingkat}} ({{dataOfTable.length}} Classes)</p>
              </a-col>
              <a-col :xs="{ span: 12 }" :lg="{ span: 6 }" class="mb-3">
                <h3 class="type">Total Session</h3>
                <p class="value" v-if="overviewSlot.total_session">
                  {{overviewSlot.total_session}}
                  <span>({{overviewSlot.planned_session}} Planned)</span>
                </p>
                <p class="value" v-else>-</p>
              </a-col>
              <a-col :xs="{ span: 12 }" :lg="{ span: 6 }" class="mb-3">
                <h3 class="type">Session per Week</h3>
                <p
                  class="value"
                  v-if="overviewSlot.unique_session"
                >{{overviewSlot.unique_session}} per Class</p>
                <p class="value" v-else>-</p>
              </a-col>
            </a-row>
            <!-- <a-row>
            <a-col :span="24" class="mb-4">-->
            <div class="mb-4">
              <TablePlanner :dataTable="dataOfTable" />
            </div>
            <!-- </a-col>
            </a-row>-->
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row></a-row>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import EmptyState from '@/components/app/EmptyState'
import CardBiweekly from '@/components/app/Planning/CardBiweekly/'
import TablePlanner from '@/components/app/Planning/TablePlanner/'
import moment from 'moment'
import LoadingState from '@/components/app/LoadingState'

export default {
  data() {
    return {
      headingEmptyState: 'No Data',
      descriptionEmptyState: 'You have no session on next two weeks',
      current: [0],
      mapel: '',
      tingkat: '',
      // dataTable,
    }
  },
  components: {
    LoadingState,
    EmptyState,
    VueSlickCarousel,
    CardBiweekly,
    TablePlanner,
  },
  methods: {
    showPrev() {
      this.$refs.carousel.prev()
    },
    showNext() {
      this.$refs.carousel.next()
    },
    showOverview(mapel, tingkat) {
      this.mapel = mapel
      this.tingkat = tingkat
      this.$store.dispatch('slot/FETCH_OVERVIEW_SLOT_TEACHER', { idGuru: this.user.id, namaMapel: this.mapel, tingkat: this.tingkat })
    },
  },
  created() {
    this.$store.dispatch('slot/FETCH_BIWEEKLY_TOPIC', { idGuru: this.user.id })
    this.$store.dispatch('slot/FETCH_MENU_SLOT_TEACHER', { idGuru: this.user.id })
      .then(data => {
        if (data[0]) {
          this.$store.dispatch('slot/FETCH_OVERVIEW_SLOT_TEACHER', { idGuru: this.user.id, namaMapel: data[0]['mata_pelajaran.nama'], tingkat: data[0]['kela.tingkat'] })
        }
      })
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    settings() {
      const settings = {
        arrows: true,
        centerMode: true,
        focusOnSelect: false,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        centerPadding: '-20px',
      }

      const isMobile = this.isMobile
      const isTablet = this.$store.getters.isTablet
      // console.log(isTablet)
      if (!isTablet && isMobile) {
        settings.centerPadding = '-30px'
        settings.slidesToShow = 1
      } else if (isTablet && !isMobile) {
        // console.log('masuk 150')
        settings.centerPadding = '-200px'
        settings.slidesToShow = 2
      } else {
        settings.centerPadding = '-20px'
        settings.slidesToShow = 3
        // this.settings.centerPadding = '-20px'
        // this.settings.slidesToShow = 1
      }

      return settings
    },
    biweeklyTopic() {
      return this.$store.state.slot.biweeklyTopic
    },
    isDesktop() {
      // console.log(!this.$store.getters.isMobile && !this.$store.getters.isTablet)
      return !this.$store.getters.isMobile && !this.$store.getters.isTablet
    },
    menuSlots() {
      return this.$store.state.slot.menuSlots
    },
    overviewSlot() {
      return this.$store.state.slot.overviewSlot
    },
    dataOfTable() {
      return this.$store.state.slot.dataOfTablePlanning
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    biweeklyDate() {
      const monday = moment().startOf('isoWeek').format('DD MMM YYYY')
      const friday = moment().endOf('week').add(-2, 'days').format('DD MMM YYYY')
      return `${monday} - ${moment(friday, 'DD MMM YYYY').add(1, 'weeks').format('DD MMM YYYY')}`
    },
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="scss">
.planning {
  h2.titleBiweekly {
    margin-top: 0 !important;
  }
  .slick-next {
    right: 0.4%;
  }

  .slick-prev {
    left: -2%;
    z-index: 999;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 3.5rem;
    opacity: 1;
    color: #86d8b3;
    z-index: 1000;
  }
}

.btnNav {
  top: 85px;
  display: inline-block;
  position: relative;
  z-index: 999;
}

.btnNav .btnPrev,
.btnNext {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  color: #1b90ff;
  font-size: 30px;
  box-shadow: 0px 5px 15px #090e2518;
}

.btnNav .btnPrev {
  left: -25px;
}
.btnNav .btnNext {
  left: 1160px;
}

.nav-slots {
  padding: 20px;
}
.card-slots {
  width: 100%;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail-slot {
  padding: 20px 40px;
}

.detail-slot .type {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
}

.detail-slot .value {
  padding-top: 5px;
  font-family: "Mukta", "sans-serif";
  font-size: 16px;
}

.detail-slot .value span {
  color: #1b90ff;
}
</style>
