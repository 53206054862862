<template>
  <div class="cardBiweekly">
    <div
      class="d-flex flex-column head"
      :style="{
      background: mataPelajaran ?
                  `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/resources/images/covers/${mataPelajaran}/cover.png')` :
                  `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/resources/images/covers/Matematika/cover.png')`,
      backgroundSize: '100% 100%'
    }"
    >
      <div class="card-header card-header-flex border-bottom-0">
        <div class="d-flex flex-column justify-content-center">
          <div class="cardCover">
            <h2>{{kelas}} | {{mataPelajaran}}</h2>
            <h5>{{item.nama}}</h5>
            <h6>{{item.subtopik}}</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="card cardContent card-borderless">
      <div class="card-body px-4">
        <div
          v-for="(itemKelas, index) in item.sesis"
          :key="index"
          class="d-flex flex-nowrap align-items-center pb-2"
        >
          <div class="mr-auto">
            <a
              :style="{
                width: '100px',
              }"
              @click.prevent="toDetail(itemKelas.id)"
              class="btn btn-outline-success btnKelas"
            >{{itemKelas.slot.kela.tingkat}}-{{itemKelas.slot.kela.simbol}}</a>
            <div class="font-weight-bold font-size-15 text-dark text-class"></div>
          </div>
          <div
            class="ml-1 font-size-14 text-dark text-right text-date"
          >{{getDate(itemKelas.tanggal_sesi)}}, {{itemKelas.slot.model_jadwal.jam_mulai}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: ['item'],
  methods: {
    toDetail(id) {
      // console.log(id)
      this.$router.push({ name: 'Detail Schedule Teacher', params: { id } })
    },
    getDate(date) { return `${moment(date).format('DD MMM YYYY')}` },
    // getTime(model) {
    //   const { durasi, jam_mulai: jamMulai, posisi } = model
    //   return moment(jamMulai, 'HH:mm').format('HH:mm')
    // },
  },
  computed: {
    kelas() {
      return this.item.sesis[0].slot.kela.tingkat
    },
    mataPelajaran() {
      return this.item.sesis[0].slot.mata_pelajaran.nama
    },
  },
}
</script>
<style scoped>
.head {
  background-size: cover;
  background-position: center center;
  border-radius: 8px 8px 0 0;
  height: 102px;
}

.cardBiweekly {
  margin-top: 20px;
  width: 85%;
  /* min-height: 400px; */
  z-index: 997;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.cardBiweekly:hover {
  transform: translate(0, -5px);
  box-shadow: 0px 0px 20px #0419301a;
}

.cardCover h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: white;
}

.cardCover h5,
h6 {
  font-family: "Mukta", "sans-serif";
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  color: white;
}

.cardCover h5 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cardCover h6 {
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardContent {
  border-radius: 0 0 8px 8px;
  min-height: 208px;
}

.card-body .btnKelas {
  width: 150px;
  border-color: #038080;
  color: #038080;
}

.card-body .btnKelas:hover {
  background: #038080 !important;
  border-color: #038080 !important;
  color: white;
}
.card-body .btnKelas:active {
  background: #038080 !important;
  color: white;
}

.cardContent .text-class {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
}

.cardContent .text-date {
  font-family: "Mukta", "sans-serif";
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

.cardStats {
  font-family: "Mukta", "sans-serif";
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  color: #1b90ff;
  font-size: 20px;
}
.cardStats .icon {
  color: #1b90ff;
  font-size: 20px;
}
</style>
